<template>
  <div class="d-flex align-center justify-center full-height">
    <ant-loading />
  </div>
</template>

<script>
import AntLoading from "@/components/AntLoading";
import {ACC_REFRESH_TOKEN, ACC_ROUTE, ACC_TOKEN} from "@/services/forge/autodesk-construction-cloud";

export default {
  name: "AutodeskConstructionCloudCallback",
  components: {AntLoading},
  mounted() {
    const code = this.$route.query.code
    this.$store.dispatch("fetchAccAccessToken", code).then(response => {
      let url = this.$cookies.get(ACC_ROUTE)
      this.$cookies.set(ACC_TOKEN, response.access_token, response.expires_in)
      this.$cookies.set(ACC_REFRESH_TOKEN, response.refresh_token, response.expires_in)
      this.$router.push(`${url}`)
    })
  }
}
</script>

<style scoped>

</style>
